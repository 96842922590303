.App {
  text-align: center;
  padding: 20px;
}

.table-container {
  position: relative;
  max-height: 60vh;
  overflow-y: auto;
}

table {
  margin-top: 20px;
  width: auto;
}

th, td {
  text-align: center;
  padding: 5px;
  white-space: nowrap;
}

input[type="number"] {
  text-align: center;
}

/* Sticky header and footer styles */
.sticky-header th {
  position: sticky;
  top: 0;
  background-color: grey !important;
  z-index: 10;
  color: white;
}

.sticky-footer>tr{
  position: sticky;
  bottom: 0;
  background-color: cornflowerblue !important;
  z-index: 10;
  color: white;
}
